import Vue from 'vue'
import Router from 'vue-router'
import login from '@/view/login/index'
Vue.use(Router)
const originalPush = Router.prototype.push
   Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default new Router({
	routes: [
		{
			path: '/',
			name: 'login',
			component: login,
			meta: {
				title: '登录'
			}
		},
		{
			path: '/archives_index',
			name: 'archives_index',
			component: () => import('@/view/archives/index'),
			meta: {
				title: '教师档案'
			}
		},
		{
			path: '/archives_details',
			name: 'archives_details',
			component: () => import('@/view/archives/details'),
			meta: {
				title: '教师档案'
			}
		},
		{
			path: '/paike_index',
			name: 'paike_index',
			component: () => import('@/view/paike/index'),
			meta: {
				title: '教师排课'
			}
		},
		{
			path: '/paike_create',
			name: 'paike_create',
			component: () => import('@/view/paike/create'),
			meta: {
				title: '教师排课'
			}
		},
		{
			path: '/paike_auto',
			name: 'paike_auto',
			component: () => import('@/view/paike/auto'),
			meta: {
				title: '自动排课'
			}
		},
		{
			path: '/deploy',
			name: 'deploy',
			component: () => import('@/view/paike/deploy'),
			meta: {
				title: '排课'
			}
		},
		{
			path: '/station',
			name: 'station',
			component: () => import('@/view/station/index'),
			meta: {
				title: '岗位安排'
			}
		},
		{
			path: '/student',
			name: 'student',
			component: () => import('@/view/student/index'),
			meta: {
				title: '学生档案'
			}
		},
		{
			path: '/studentDetails',
			name: 'studentDetails',
			component: () => import('@/view/student/details'),
			meta: {
				title: '学生档案'
			}
		},
		{
			path: '/student/tuition',
			name: 'studentTuition',
			component: () => import('@/view/student/tuition'),
			meta: {
				title: '学费账单'
			}
		},
		{
			path: '/student/examine',
			name: 'studentExamine',
			component: () => import('@/view/student/examine'),
			meta: {
				title: '企业考察'
			}
		},
		{
			path: '/student/test',
			name: 'studentTest',
			component: () => import('@/view/student/test'),
			meta: {
				title: '教师实践'
			}
		},
		{
			path: '/student/chat',
			name: 'studentChat',
			component: () => import('@/view/student/chat'),
			meta: {
				title: '师生交流'
			}
		},
		{
			path: '/student/cert',
			name: 'studentCert',
			component: () => import('@/view/student/cert'),
			meta: {
				title: '考证'
			}
		},

		{
			path: '/index',
			name: 'index',
			component: () => import('@/view/index/index'),
			meta: {
				title: '首页'
			}
		},
		{
			path: '/manageSchool',
			name: 'manageSchool',
			component: () => import('@/view/manage/school/index'),
			meta: {
				title: '校区管理'
			}
		},
		{
			path: '/manageSection',
			name: 'manageSection',
			component: () => import('@/view/manage/section/index'),
			meta: {
				title: '部门设置'
			}
		},
		{
			path: '/managePeople',
			name: 'managePeople',
			component: () => import('@/view/manage/people/index'),
			meta: {
				title: '人员管理'
			}
		},
		{
			path: '/manageSubject',
			name: 'manageSubject',
			component: () => import('@/view/manage/subject/index'),
			meta: {
				title: '课程管理'
			}
		},
		{
			path: '/subjectType',
			name: 'subjectType',
			component: () => import('@/view/manage/subject/subjectType'),
			meta: {
				title: '课程类型'
			}
		},
		{
			path: '/manageCourse',
			name: 'manageCourse',
			component: () => import('@/view/manage/course/index'),
			meta: {
				title: '课程时段'
			}
		},
		{
			path: '/managePlace',
			name: 'managePlace',
			component: () => import('@/view/manage/place/index'),
			meta: {
				title: '场地设置'
			}
		},
		{
			path: '/grade',
			name: 'grade',
			component: () => import('@/view/setting/grade'),
			meta: {
				title: '年级班级'
			}
		},
		{
			path: '/setting/major',
			name: 'settingMajor',
			component: () => import('@/view/setting/major'),
			meta: {
				title: '专业管理'
			}
		},
		{
			path: '/setting/system_process_member',
			name: 'settingSystemProcessMember',
			component: () => import('@/view/setting/systemProcessMember'),
			meta: {
				title: '审批人设定'
			}
		},
		{
			path: '/student_enroll',
			name: 'student_enroll',
			component: () => import('@/view/manage/student/enroll.vue'),
			meta: {
				title: '报名填报'
			}
		},
		{
			path: '/student_refund',
			name: 'student_refund',
			component: () => import('@/view/manage/student/refund.vue'),
			meta: {
				title: '退费管理'
			}
		},
		{
			path: '/student_management',
			name: 'student_management',
			component: () => import('@/view/manage/student/index'),
			meta: {
				title: '学籍管理'
			}
		},
		{
			path: '/student_card',
			name: 'student_card',
			component: () => import('@/view/manage/student/card'),
			meta: {
				title: '学籍卡'
			}
		},
		{
			path: '/semester',
			name: 'semester',
			component: () => import('@/view/manage/semester/index'),
			meta: {
				title: '学年学期'
			}
		},
		{
			path: '/semester/week',
			name: 'semesterWeek',
			component: () => import('@/view/manage/semester/week'),
			meta: {
				title: '学年学期'
			}
		},
		{
			path: '/divide',
			name: 'divide',
			component: () => import('@/view/setting/divide-class'),
			meta: {
				title: '学生分班'
			}
		},
		{
			path: '/divide_detail',
			name: 'divide_detail',
			component: () => import('@/view/setting/student_placement_detail'),
			meta: {
				title: '分班详情'
			}
		},
		{
			path: '/exam',
			name: 'exam',
			component: () => import('@/view/manage/exam/index'),
			meta: {
				title: '考试管理'
			}
		},
		{
			path: '/exam2',
			name: 'exam2',
			component: () => import('@/view/manage/exam/exam2'),
			meta: {
				title: '考试成绩'
			}
		},
		{
			path: '/exam_detail',
			name: 'exam_detail',
			component: () => import('@/view/manage/exam/exam_detail'),
			meta: {
				title: '成绩详情'
			}
		},
		{
			path: '/resit',
			name: 'resit',
			component: () => import('@/view/manage/exam/resit'),
			meta: {
				title: '补考查询'
			}
		},
		{
			path: '/stationDetails',
			name: 'stationDetails',
			component: () => import('@/view/station/details'),
			meta: {
				title: '岗位配置'
			}
		},
		{
			path: '/admit',
			name: 'admit',
			component: () => import('@/view/manage/admit/index'),
			meta: {
				title: '招生管理'
			}
		},
		{
			path: '/rule',
			name: 'rule',
			component: () => import('@/view/paike/rule'),
			meta: {
				title: '排课规则'
			}
		},
		{
			path: '/dormitory',
			name: 'dormitory',
			component: () => import('@/view/manage/dormitory/dormitory'),
			meta: {
				title: '住宿管理'
			}
		},
		{
			path: '/coursePatrol',
			name: 'coursePatrol',
			component: () => import('@/view/course_patrol/index'),
			meta: {
				title: '晨午检'
			}
		},
		{
			path: '/setEvaluate',
			name: 'setEvaluate',
			component: () => import('@/view/manage/setEvaluate/index'),
			meta: {
				title: '评价配置'
			}
		},
		{
			path: '/courseSystem',
			name: 'courseSystem',
			component: () => import('@/view/courseSystem/index'),
			meta: {
				title: '课程体系'
			}
		},
		{
			path: '/approvalProcess',
			name: 'approvalProcess',
			component: () => import('@/view/rule/approval_process/index'),
			meta: {
				title: '流程审批'
			}
		},
		{
			path: '/leave',
			name: 'leave',
			component: () => import('@/view/rule/leave/leave'),
			meta: {
				title: '请假审批'
			}
		},
		{
			path: '/notice',
			name: 'notice',
			component: () => import('@/view/manage/notice/index'),
			meta: {
				title: '学校公告'
			}
		},
		{
			path: '/myClass',
			name: 'myClass',
			component: () => import('@/view/index/myClass/myClass'),
			meta: {
				title: '我的班级'
			}
		},
		{
			path: '/myClassDetails',
			name: 'myClassDetails',
			component: () => import('@/view/index/myClass/details'),
			meta: {
				title: '我的班级'
			}
		},
		{
			path: '/myGrade',
			name: 'myGrade',
			component: () => import('@/view/index/myGrade/myGrade'),
			meta: {
				title: '我的年级'
			}
		},
		{
			path: '/CourseCourse',
			name: 'CourseCourse',
			component: () => import('@/view/index/CourseCourse/index'),
			meta: {
				title: '课程申报'
			}
		},
		{
			path: '/repair',
			name: 'repair',
			component: () => import('@/view/index/repair/index'),
			meta: {
				title: '校园报修'
			}
		},
		{
			path: '/safety',
			name: 'safety',
			component: () => import('@/view/rule/safety/index'),
			meta: {
				title: '安全隐患'
			}
		},
		{
			path: '/adjustClass',
			name: 'adjustClass',
			component: () => import('@/view/index/adjustClass/index'),
			meta: {
				title: '安全隐患'
			}
		},
		{
			path: '/asset',
			name: 'asset',
			component: () => import('@/view/manage/asset/index'),
			meta: {
				title: '资产管理'
			}
		},
		{
			path: '/supplies',
			name: 'supplies',
			component: () => import('@/view/manage/asset/supplies'),
			meta: {
				title: '资产管理'
			}
		},
		{
			path: '/fileIo',
			name: 'fileIo',
			component: () => import('@/view/fileIo/index'),
			meta: {
				title: '通知文件'
			}
		},
		{
			path: '/liucheng',
			name: 'liucheng',
			component: () => import('@/view/rule/index'),
			meta: {
				title: '流程审批'
			}
		},
		{
			path: '/print',
			name: 'print',
			component: () => import('@/view/rule/print/index'),
			meta: {
				title: '流程打印'
			}
		},
		{
			path: '/dorm',
			name: 'dorm',
			component: () => import('@/view/manage/dorm/index'),
			meta: {
				title: '宿舍管理'
			}
		},
		{
			path: '/dorm1',
			name: 'dorm1',
			component: () => import('@/view/manage/dorm/dorm1'),
			meta: {
				title: '宿舍管理'
			}
		},
		{
			path: '/dorm2',
			name: 'dorm3',
			component: () => import('@/view/manage/dorm/dorm2'),
			meta: {
				title: '宿舍管理'
			}
		},
		{
			path: '/meeting',
			name: 'meeting',
			component: () => import('@/view/rule/meeting'),
			meta: {
				title: '会议管理'
			}
		},
		{
			path: '/switching',
			name: 'switching',
			component: () => import('@/view/rule/switching'),
			meta: {
				title: '调课列表'
			}
		},{
			path: '/schoolCalendar',
			name: 'schoolCalendar',
			component: () => import('@/view/schoolCalendar/index'),
			meta: {
				title: '学校校历'
			}
		},{
			path: '/classHour',
			name: 'classHour',
			component: () => import('@/view/index/classHour/index'),
			meta: {
				title: '我的课时'
			}
		},{
			path: '/comment',
			name: 'comment',
			component: () => import('@/view/rule/comment'),
			meta: {
				title: '评语管理'
			}
		},{
			path: '/clockingIn',
			name: 'clockingIn',
			component: () => import('@/view/rule/clockingIn'),
			meta: {
				title: '考勤管理'
			}
		},{
			path: '/user',
			name: 'user',
			component: () => import('@/view/setting/user'),
			meta: {
				title: '人员管理'
			}
		},{
			path: '/statement',
			name: 'statement',
			component: () => import('@/view/statement/index'),
			meta: {
				title: '报表中心'
			}
		},{
			path: '/teacherlist',
			name: 'teacherlist',
			component: () => import('@/view/statement/teacherlist'),
			meta: {
				title: '教师数据列表'
			}
		},{
			path: '/teacher/evaluation',
			name: 'teacherEvaluation',
			component: () => import('@/view/teacher/evaluation'),
			meta: {
				title: '教师教评'
			}
		},
		{
			path: '/studentList',
			name: 'studentList',
			component: () => import('@/view/statement/studentList'),
			meta: {
				title: '学生数据列表'
			}
		},{
			path: '/teacherCheck',
			name: 'teacherCheck',
			component: () => import('@/view/statement/teacherCheck'),
			meta: {
				title: '教师请假列表'
			}
		},{
			path: '/studentCheck',
			name: 'studentCheck',
			component: () => import('@/view/statement/studentCheck'),
			meta: {
				title: '学生请假列表'
			}
		},{
			path: '/work',
			name: 'work',
			component: () => import('@/view/flow/work'),
			meta: {
				title: '公文流转'
			}
		},{
			path: '/teacherleave',
			name: 'teacherleave',
			component: () => import('@/view/flow/teacherleave'),
			meta: {
				title: '我的请假'
			}
		},{
			path: '/activitylist',
			name: 'activitylist',
			component: () => import('@/view/flow/activitylist'),
			meta: {
				title: '活动申请'
			}
		},{
			path: '/goods',
			name: 'goods',
			component: () => import('@/view/flow/goods'),
			meta: {
				title: '物品领用'
			}
		},{
			path: '/evection',
			name: 'evection',
			component: () => import('@/view/flow/evection'),
			meta: {
				title: '公务出差'
			}
		},{
			path: '/danger',
			name: 'danger',
			component: () => import('@/view/flow/danger'),
			meta: {
				title: '安全隐患'
			}
		},{
			path: '/warning',
			name: 'warning',
			component: () => import('@/view/setting/warning'),
			meta: {
				title: '预警中心'
			}
		},{
			path: '/disciplineUser',
			name: 'disciplineUser',
			component: () => import('@/view/setting/disciplineUser'),
			meta: {
				title: '科组人员'
			}
		},{
			path: '/rewards',
			name: 'rewards',
			component: () => import('@/view/setting/rewards/index'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/studentrewards',
			name: 'studentrewards',
			component: () => import('@/view/setting/rewards/student'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/classRewards',
			name: 'classRewards',
			component: () => import('@/view/setting/rewards/classRewards'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/dormrewards',
			name: 'dormrewards',
			component: () => import('@/view/setting/rewards/dorm'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/purchase',
			name: 'purchase',
			component: () => import('@/view/flow/purchase'),
			meta: {
				title: '公物申购'
			}
		},{
			path: '/groupStudy',
			name: 'groupStudy',
			component: () => import('@/view/group/groupStudy'),
			meta: {
				title: '小组评分'
			}
		},{
			path: '/groupStatistics',
			name: 'groupStatistics',
			component: () => import('@/view/group/groupStatistics'),
			meta: {
				title: '小组统计'
			}
		},{
			path: '/tutor',
			name: 'tutor',
			component: () => import('@/view/manage/tutor/index'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/tutorScore',
			name: 'tutorScore',
			component: () => import('@/view/manage/tutor/score'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/tutorSet',
			name: 'tutorSet',
			component: () => import('@/view/manage/tutor/set'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/ziping',
			name: 'ziping',
			component: () => import('@/view/manage/tutor/ziping'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/tutorDetail',
			name: 'tutorDetail',
			component: () => import('@/view/manage/tutor/details'),
			meta: {
				title: '约谈详情'
			}
		},{
			path: '/groupIndex',
			name: 'groupIndex',
			component: () => import('@/view/group/index'),
			meta: {
				title: '小组配置'
			}
		},{
			path: '/myMeeting',
			name: 'myMeeting',
			component: () => import('@/view/rule/myMeeting'),
			meta: {
				title: '我的会议'
			}
		},{
			path: '/teacherCheckDetails',
			name: 'teacherCheckDetails',
			component: () => import('@/view/statement/teacherCheckDetails'),
			meta: {
				title: '教师考勤详情'
			}
		},{
			path: '/pronoun',
			name: 'pronoun',
			component: () => import('@/view/rule/pronoun'),
			meta: {
				title: '我的代课'
			}
		},{
			path: '/leaveManage',
			name: 'leaveManage',
			component: () => import('@/view/index/leaveManage'),
			meta: {
				title: '请假管理'
			}
		},{
			path: '/official',
			name: 'official',
			component: () => import('@/view/setting/official/index'),
			meta: {
				title: '微官网管理'
			}
		},{
			path: '/addArticle',
			name: 'addArticle',
			component: () => import('@/view/setting/official/addArticle'),
			meta: {
				title: '添加资讯'
			}
		},{
			path: '/setBanner',
			name: 'setBanner',
			component: () => import('@/view/setting/official/official'),
			meta: {
				title: '微官网管理'
			}
		},{
			path: '/screen',
			name: 'screen',
			component: () => import('@/view/flow/screen'),
			meta: {
				title: '电子屏申请'
			}
		},{
			path: '/chapter',
			name: 'chapter',
			component: () => import('@/view/flow/chapter'),
			meta: {
				title: '公章使用申请'
			}
		},{
			path: '/retroactive',
			name: 'retroactive',
			component: () => import('@/view/flow/retroactive'),
			meta: {
				title: '打卡记录'
			}
		},{
			path: '/schoolbus',
			name: 'schoolbus',
			component: () => import('@/view/flow/schoolbus'),
			meta: {
				title: '校车使用申请'
			}
		},{
			path: '/ladder',
			name: 'ladder',
			component: () => import('@/view/flow/ladder'),
			meta: {
				title: '功能室申请'
			}
		},{
			path: '/ladderManage',
			name: 'ladderManage',
			component: () => import('@/view/flow/ladderManage'),
			meta: {
				title: '功能室管理'
			}
		},{
			path: '/daochu',
			name: 'daochu',
			component: () => import('@/view/manage/dorm/daochu'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/yujing',
			name: 'yujing',
			component: () => import('@/view/manage/dorm/yujing'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/plliang',
			name: 'plliang',
			component: () => import('@/view/manage/dorm/plliang'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/xuesheng',
			name: 'xuesheng',
			component: () => import('@/view/manage/dorm/xuesheng'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/dormAdd',
			name: 'dormAdd',
			component: () => import('@/view/manage/dorm/add'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/schoolPhoto',
			name: 'schoolPhoto',
			component: () => import('@/view/setting/official/schoolPhoto'),
			meta: {
				title: '校园相册'
			}
		},{
			path: '/schoolVideo',
			name: 'schoolVideo',
			component: () => import('@/view/setting/official/schoolVideo'),
			meta: {
				title: '校园视频'
			}
		},{
			path: '/classPhoto',
			name: 'classPhoto',
			component: () => import('@/view/index/myClass/classPhoto'),
			meta: {
				title: '班级相册'
			}
		},{
			path: '/classHonor',
			name: 'classHonor',
			component: () => import('@/view/index/myClass/classHonor'),
			meta: {
				title: '班级荣誉'
			}
		},{
			path: '/classAccess',
			name: 'classAccess',
			component: () => import('@/view/index/myClass/classAccess'),
			meta: {
				title: '出入记录'
			}
		},{
			path: '/classVideo',
			name: 'classVideo',
			component: () => import('@/view/index/myClass/classVideo'),
			meta: {
				title: '班级视频'
			}
		},{
			path: '/classNotice',
			name: 'classNotice',
			component: () => import('@/view/index/myClass/classNotice'),
			meta: {
				title: '班级公告'
			}
		},{
			path: '/addClassNotice',
			name: 'addClassNotice',
			component: () => import('@/view/index/myClass/addClassNotice'),
			meta: {
				title: '发布公告'
			}
		},{
			path: '/classMien',
			name: 'classMien',
			component: () => import('@/view/index/myClass/classMien'),
			meta: {
				title: '班级风采'
			}
		},{
			path: '/addClassMien',
			name: 'addClassMien',
			component: () => import('@/view/index/myClass/addClassMien'),
			meta: {
				title: '班级风采'
			}
		},
		{
			path: '/train/base',
			name: 'TrainBase',
			component: () => import('@/view/train/base.vue'),
			meta: {
				title: '基地管理'
			}
		},
		{
			path: '/train/manage',
			name: 'TrainManage',
			component: () => import('@/view/train/manage.vue'),
			meta: {
				title: '实训室管理'
			}
		},
		{
			path: '/train/check',
			name: 'TrainCheck',
			component: () => import('@/view/train/check.vue'),
			meta: {
				title: '实训检查登记'
			}
		},
		{
			path: '/train/borrow',
			name: 'TrainBorrow',
			component: () => import('@/view/train/borrow.vue'),
			meta: {
				title: '实训设备借用'
			}
		},
		{
			path: '/train/get',
			name: 'TrainGet',
			component: () => import('@/view/train/get.vue'),
			meta: {
				title: '实训设备领用'
			}
		},
		{
			path: '/examine',
			name: 'examine',
			component: () => import('@/view/setting/trainingBase/examine'),
			meta: {
				title: '实训室检查'
			}
		},
		{
			path: '/train/audit/base',
			name: 'TrainAuditBase',
			component: () => import('@/view/train/audit/base.vue'),
			meta: {
				title: '基地审批'
			}
		},
		{
			path: '/train/audit/room',
			name: 'TrainAuditRoom',
			component: () => import('@/view/train/audit/room.vue'),
			meta: {
				title: '实训室审批'
			}
		},

		{
			path: '/account',
			name: 'account',
			component: () => import('@/view/setting/official/account'),
			meta: {
				title: '班牌账号'
			}
		},{
			path: '/ClubWorkPlan',
			name: 'ClubWorkPlan',
			component: () => import('@/view/ClubWorkPlan/index'),
			meta: {
				title: '社团管理'
			}
		},{
			path: '/ClubWorkPlanDetails',
			name: 'ClubWorkPlanDetails',
			component: () => import('@/view/ClubWorkPlan/details'),
			meta: {
				title: '社团管理'
			}
		},
		{
			path: '/schooling',
			name: 'schooling',
			component: () => import('@/view/ClubWorkPlan/schooling'),
			meta: {
				title: '社团管理'
			}
		},
		{
			path: '/expenditure',
			name: 'expenditure',
			component: () => import('@/view/ClubWorkPlan/expenditure'),
			meta: {
				title: '社团管理'
			}
		},{
			path: '/equipment',
			name: 'equipment',
			component: () => import('@/view/setting/trainingBase/equipment'),
			meta: {
				title: '社团管理'
			}
		},{
			path: '/appraise',
			name: 'appraise',
			component: () => import('@/view/appraise/index'),
			meta: {
				title: '文明班评比'
			}
		},{
			path: '/appraiseList',
			name: 'appraiseList',
			component: () => import('@/view/appraise/appraiseList'),
			meta: {
				title: '文明班评比'
			}
		},{
			path: '/staffManage',
			name: 'staffManage',
			component: () => import('@/view/manage/staff/index'),
			meta: {
				title: '教研组管理'
			}
		},{
			path: '/classroom',
			name: 'classroom',
			component: () => import('@/view/manage/classroom/index'),
			meta: {
				title: '教室管理'
			}
		},{
			path: '/assess',
			name: 'assess',
			component: () => import('@/view/teacher/assess/index'),
			meta: {
				title: '教师考核'
			}
		},{
			path: '/conduct',
			name: 'conduct',
			component: () => import('@/view/conduct/index'),
			meta: {
				title: '操行评定'
			}
		},{
			path: '/inSchool',
			name: 'inSchool',
			component: () => import('@/view/inSchool/index'),
			meta: {
				title: '进出校管理'
			}
		},{
			path: '/myInSchool',
			name: 'myInSchool',
			component: () => import('@/view/inSchool/myInSchool'),
			meta: {
				title: '进出校管理'
			}
		},{
			path: '/recruitStudent',
			name: 'recruitStudent',
			component: () => import('@/view/recruitStudent/index'),
			meta: {
				title: '招生管理'
			}
		},{
			path: '/foodManage',
			name: 'foodManage',
			component: () => import('@/view/foodManage/index'),
			meta: {
				title: '食材管理'
			}
		},{
			path: '/stockOut',
			name: 'stockOut',
			component: () => import('@/view/foodManage/stockOut'),
			meta: {
				title: '食材管理'
			}
		},
		{
			path: '/schoolings',
			name: 'schoolings',
			component: () => import('@/view/setting/schooling/index'),
			meta: {
				title: '授课安排'
			}
		},
		{
			path: '/schoolings/arrange',
			name: 'schoolingsArrange',
			component: () => import('@/view/setting/schooling/arrange'),
			meta: {
				title: '授课安排'
			}
		},
		{
			path: '/textbook',
			name: 'textbook',
			component: () => import('@/view/manage/textbook/index'),
			meta: {
				title: '教材管理'
			}
		},{
			path: '/textbookOrder',
			name: 'textbookOrder',
			component: () => import('@/view/manage/textbook/order'),
			meta: {
				title: '订单管理'
			}
		},{
			path: '/teach',
			name: 'teach',
			component: () => import('@/view/teach/index'),
			meta: {
				title: '教学计划'
			}
		},{
			path: '/practice',
			name: 'practice',
			component: () => import('@/view/practice/index'),
			meta: {
				title: '就业管理'
			}
		},
		{
			path: '/competition',
			name: 'competition',
			component: () => import('@/view/competition/index'),
			meta: {
				title: '竞赛管理'
			}
		},
		{
			path: '/classSchedule',
			name: 'classSchedule',
			component: () => import('@/view/classSchedule/index'),
			meta: {
				title: '课表查询'
			}
		},
		{
			path: '/initialCourse',
			name: 'initialCourse',
			component: () => import('@/view/classSchedule/initialCourse'),
			meta: {
				title: '课表查询'
			}
		},
		{
			path: '/deviceManage',
			name: 'deviceManage',
			component: () => import('@/view/manage/device/index'),
			meta: {
				title: '门禁设备管理'
			}
		},
		{
			path: '/deviceTimeManage',
			name: 'deviceTimeManage',
			component: () => import('@/view/manage/device/time'),
			meta: {
				title: '门禁设备管理'
			}
		},

	]
})
