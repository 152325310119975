import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const student = {
	//档案总览
	studentIndex(params) {
		return axios.get(`${base.sq}/teacher/teacher_file_overview`, params);
	},
	//档案列表
	studentList(params) {
		return axios.post(`${base.sq}/student_archives/index`, qs.stringify(params));
	},
	//档案详情
	studentDetail(params) {
		return axios.post(`${base.sq}/student_archives/detail`, qs.stringify(params));
	},
	//档案详情筛选
	studentdetailScreening(params) {
		return axios.post(`${base.sq}/student_archives/detailScreening`, qs.stringify(params));
	},
	//学年列表
	entYear(params) {
		return axios.post(`${base.sq}/student/entYear`, qs.stringify(params));
	},
	//学籍列表
	stuList(params) {
		return axios.post(`${base.sq}/student/index`, qs.stringify(params));
	},
	//添加学籍
	addStu(params) {
		return axios.post(`${base.sq}/student/add`, qs.stringify(params));
	},
	//编辑学籍
	editStu(params) {
		return axios.post(`${base.sq}/student/edit`, qs.stringify(params));
	},
	//学籍详情
	detailStu(params) {
		return axios.post(`${base.sq}/student/get`, qs.stringify(params));
	},
	//删除学籍
	delStu(params) {
		return axios.post(`${base.sq}/student/del`,  qs.stringify(params));
	},
	// 办理离校
	leaveStu(params) {
		return axios.post(`${base.sq}/student/leave`,  qs.stringify(params));
	},
	// 批量导入模板接口
	ImportTempStu(params) {
		return axios.post(`${base.sq}/student/ImportTemp`,  qs.stringify(params));
	},
	// 批量更新模板接口
	updateImportTempStu(params) {
		return axios.post(`${base.sq}/student/updateImportTemp`,  qs.stringify(params));
	},
	// 批量更新
	updateImportStu(params) {
		return axios.post(`${base.sq}/student/updateImport`,  params);
	},
	// 批量导入
	importStu(params) {
		return axios.post(`${base.sq}/student/import`,  params);
	},
	// 学籍导出功能
	exportStu(params) {
		return axios.post(`${base.sq}/student/derive`,  qs.stringify(params));
	},
	//分班列表
	csdList(params) {
		return axios.post(`${base.sq}/csd/index`, qs.stringify(params));
	},
	//添加分班
	addCsd(params) {
		return axios.post(`${base.sq}/csd/add`, qs.stringify(params));
	},
	//编辑分班
	editCsd(params) {
		return axios.post(`${base.sq}/csd/edit`, qs.stringify(params));
	},
	//删除分班
	delCsd(params) {
		return axios.post(`${base.sq}/csd/del`, params);
	},
	//分班详情
	detailCsd(params) {
		return axios.post(`${base.sq}/csd/get`, qs.stringify(params));
	},
	//设置分班状态
	statusCsd(params) {
		return axios.post(`${base.sq}/csd/setStatus`, qs.stringify(params));
	},
	//分班详情列表接口
	ccsdList(params) {
		return axios.post(`${base.sq}/csdd/index`, qs.stringify(params));
	},
	//获取可分班的学生列表接口
	ccsdStuedentList(params) {
		return axios.post(`${base.sq}/csd/studentList`, qs.stringify(params));
	},
	//分班详情添加接口
	addCcsd(params) {
		return axios.post(`${base.sq}/csd/addStudent`, qs.stringify(params));
	},
	//分班详情修改接口
	editCcsd(params) {
		return axios.post(`${base.sq}/csd/editStudent`, qs.stringify(params));
	},
	//分班详情删除接口
	delCcsd(params) {
		return axios.post(`${base.sq}/csd/delStudent`, qs.stringify(params));
	},
	// 分班导出
	export(params){
		return axios.post(`${base.sq}/csd/derive `, qs.stringify(params));
	},
	// 分班更新录入模板
	updateImportTemp(params){
		return axios.post(`${base.sq}/csd/updateImportTemp`, qs.stringify(params));
	},
	// 批量更新导入
	updateImport(params){
		return axios.post(`${base.sq}/csd/updateImport`,params);
	},
	// 导入模板下载接口
	importTemp(params){
		return axios.post(`${base.sq}/csd/importTemp`, qs.stringify(params));
	},
	// 分班方案详情--导入接口
	importFenBan(params){
		return axios.post(`${base.sq}/csd/import`,params);
	},
	//考试列表
	examList(params) {
		return axios.post(`${base.sq}/exam/index`, qs.stringify(params));
	},
	//添加考试
	addexam(params) {
		return axios.post(`${base.sq}/exam/add`, qs.stringify(params));
	},
	//编辑考试
	editexam(params) {
		return axios.post(`${base.sq}/exam/edit`, qs.stringify(params));
	},
	//删除考试
	delexam(params) {
		return axios.post(`${base.sq}/exam/del`, params);
	},
	//考试信息
	detailexam(params) {
		return axios.post(`${base.sq}/exam/get`, qs.stringify(params));
	},
	//补考列表
	examResultsList(params) {
		return axios.post(`${base.sq}/ExamResults/lists`, qs.stringify(params));
	},
	// 导出补考信息
	examResultsExports(params) {
		return axios.post(`${base.sq}/Export/exports21`, qs.stringify(params));
	},
	//考试成绩详情
	detailexamscore(params) {
		return axios.post(`${base.sq}/exam_results/detail`, qs.stringify(params));
	},
	//考试成绩详情修改
	detailexamedit(params) {
		return axios.post(`${base.sq}/exam_results/edit`, qs.stringify(params));
	},
	//下载成绩导入模板
	downexam(params) {
		return axios.post(`${base.sq}/exam_results/exports`, qs.stringify(params));
	},
	//导入成绩
	exportexam(params) {
		return axios.post(`${base.sq}/exam_results/import`, params);
	},
	// 住宿情况列表
	dormitory(params) {
		return axios.post(`${base.sq}/accommodation/index`, qs.stringify(params));
	},
	// 获取可添加学生列表
	dormitoryStudentList(params) {
		return axios.post(`${base.sq}/accommodation/studentList`, qs.stringify(params));
	},
	// 添加学生
	Adddormitory(params) {
		return axios.post(`${base.sq}/accommodation/addStudent`, qs.stringify(params));
	},
	// 编辑学生住宿情况
	editdormitory(params) {
		return axios.post(`${base.sq}/accommodation/editStudent`, qs.stringify(params));
	},
	// 删除学生住宿情况
	deldormitory(params) {
		return axios.post(`${base.sq}/accommodation/delStudent`, qs.stringify(params));
	},
	// 导出学生住宿情况
	exportdormitory(params) {
		return axios.post(`${base.sq}/accommodation/derive`, qs.stringify(params));
	},
	// 批量更新导出模板
	updateImportTempdormitory(params) {
		return axios.post(`${base.sq}/accommodation/updateImportTemp`, qs.stringify(params));
	},
	// 批量更新导入
	updateImportdormitory(params) {
		return axios.post(`${base.sq}/accommodation/updateImport`, params);
	},
	// 导入下载模板
	importTempdormitory(params) {
		return axios.post(`${base.sq}/accommodation/importTemp`, qs.stringify(params));
	},
	// 住宿情况导入
	importdormitory(params) {
		return axios.post(`${base.sq}/accommodation/import`, params);
	},
	// 获取学分设置列表
	getCourseSystemList(params) {
		return axios.post(`${base.sq}/studentCreditsSet/index`,qs.stringify(params));
	},
	// 添加学分设置
	addCourseSystem(params) {
		return axios.post(`${base.sq}/studentCreditsSet/addSet`,qs.stringify(params));
	},
	// 获取学分设置详情、
	getCourseInfoSet(params) {
		return axios.post(`${base.sq}/studentCreditsSet/infoSet`,qs.stringify(params));
	},
	// 修改学分设置
	editSetCourseSystem(params) {
		return axios.post(`${base.sq}/studentCreditsSet/editSet`,qs.stringify(params));
	},
	// 删除学分设置
	delSetCourseSystem(params) {
		return axios.post(`${base.sq}/studentCreditsSet/delSet`,qs.stringify(params));
	},
	// 获取学生学分列表
	getstudentCreditsList(params) {
		return axios.post(`${base.sq}/studentCredits/index`,qs.stringify(params));
	},
	// 获取学生学分详情
	getstudentInfoCredits(params) {
		return axios.post(`${base.sq}/studentCredits/infoCredits`,qs.stringify(params));
	},
	// 学分修改
	editCredits(params) {
		return axios.post(`${base.sq}/studentCredits/editCredits`,qs.stringify(params));
	},
	// 学生排课
	studentCourse(params) {
		return axios.get(`${base.sq}/student_archives/course`,{params:params});
	},
	// 学生课表
	student_course(params) {
		return axios.get(`${base.sq}/CourseConfig/student_course`,{params:params});
	},
	// 学生请假报表
	student_statement(params) {
		return axios.get(`${base.sq}/student_leave/statement`,{params:params});
	},
	// 学生请假统计
	student_total(params) {
		return axios.get(`${base.sq}/student_leave/total`,{params:params});
	},
	// 学生请假导出
	student_derive(params) {
		return axios.get(`${base.sq}/student_leave/derive`,{params:params});
	},
	// 班级学生考勤
	myclassleave_list(params) {
		return axios.post(`${base.sq}/student_leave/leave_list`,params);
	},
	// 班级过程性记录
	myclasscheck_list(params) {
		return axios.post(`${base.sq}/course_patrol/check_list`,params);
	},
	// 座位考勤
	seatLeaveList(params) {
		return axios.post(`${base.sq}/student_leave/seat_leave_list`,params);
	},
	// 学籍卡信息
	studentCard(params) {
		return axios.post(`${base.sq}/student/student_card`,params);
	},
	// 成绩导入模板
	achievementExports(params) {
		return axios.post(`${base.sq}/achievement/exports`,params);
	},
	// 成绩导入
	achievementImport(params) {
		return axios.post(`${base.sq}/achievement/import`,params);
	},
	// 补考列表
	ExamResultsIndex(params) {
		return axios.post(`${base.sq}/ExamResults/resit`,params);
	},
	// 编辑成绩
	achievementEdit(params) {
		return axios.post(`${base.sq}/achievement/edit`,params);
	},
	// 编辑补考成绩
	resitEdit(params) {
		return axios.post(`${base.sq}/achievement/resitEdit`,params);
	},
	// 转专业记录
	getMaiorLogList(params) {
		return axios.post(`${base.sq}/ClassStudentDistribution/conversionCourseRecord`,params);
	},
	
}

export default student;
