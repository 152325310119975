import axios from "@/utils/http";
import base from "@/api/base";
import request from "@/utils/request";

const v2Semester = {
    // 获取所有学期列表
    getAllList() {
        return request({
            url: '/v2/school_api/arrange/semester_week/all',
            method: 'get',
        })
    },
    // 获取学期列表
    getList() {
        return request({
            url: '/v2/school_api/arrange/semester_week/list',
            method: 'get',
        })
    },
    // 创建教学周
    create(semesterId) {
        return request({
            url: '/v2/school_api/arrange/semester_week/create',
            method: 'post',
            data: {
                semester_id: semesterId,
            }
        })
    },
    // 保存教学周
    save(data) {
        return request({
            url: '/v2/school_api/arrange/semester_week/edit',
            method: 'post',
            data: {
                week_data: data,
            },
        })
    }
}
export default v2Semester
