import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const teachers = {
	//教师列表
	teachersList(params) {
		return axios.post(`${base.sq}/teacher_archives/index`, params);
	},
	//档案总览
	// teachersIndex(id) {
	// 	return axios.get(`${base.sq}/teacher/teacher_file_overview?teacher_id=` + id);
	// },
	//教师档案详情
	teachersIndex(params) {
		return axios.post(`${base.sq}/teacher_archives/detail`,params);
	},
	//排课首页
	paikeIndex(params) {
		return axios.get(`${base.sq}/course/index`,{
			params:params
		});
	},
	//排课配置
	paikeSet(id) {
		return axios.get(`${base.sq}/course/get_config?id=` + id);
	},
	//教师课表
	teacherCourse(params) {
		return axios.get(`${base.sq}/CourseConfig/my_course`,{params:params});
	},
	// 岗位统计
	jobWork(params){
		return axios.get(`${base.sq}/teacher_archives/job_work`,{params:params});
	},
	// 岗位工作
	edu_work(params){
		return axios.get(`${base.sq}/teacher_archives/edu_work`,{
			params:params
		});
	},
	// 岗位工作
	courseamount(params){
		return axios.get(`${base.sq}/course_config/amount`,{
			params:params
		});
	},
	
	// 岗位统计首页
	jobWorkYear(params){
		return axios.get(`${base.sq}/teacher_archives/job_work_year`,{params:params});
	},
	// 个人档案
	teacherRecord(params){
		return axios.get(`${base.sq}/teacher_record/index?teacher_id=` + params.teacher_id + `&campus_id=`+params.campus_id);
	},
	// 个人档案-添加
	teacherRecordAdd(params){
		return axios.post(`${base.sq}/teacher_record/add`,params);
	},
	// 个人档案-详情
	teacherRecordDetail(params){
		return axios.get(`${base.sq}/teacher_record/get`,{
			params:params
		});
	},
	// 专业化档案
	teacherHonor(params){
		return axios.get(`${base.sq}/teacher_honor/index`,{
			params:params
		});
	},
	// 专业化档案-添加
	teacherHonorAdd(params){
		return axios.post(`${base.sq}/teacher_honor/add`,params);
	},
	// 专业化档案-详情
	teacherHonorDetail(params){
		return axios.get(`${base.sq}/teacher_honor/get`,{
			params:params
		});
	},
	// 记录-过程性
	studentPatrol(params){
		return axios.get(`${base.sq}/student_patrol/index`,{params:params});
	},
	// 我的年级
	myGradeList(params){
		return axios.get(`${base.sq}/teacher_archives/my_grade`,{params:params});
	},
	// 考勤统计
	attendance(params){
		return axios.get(`${base.sq}/teacher_archives/attendance`,{params:params});
	},
	// 调课记录、
	eduWork(params){
		return axios.get(`${base.sq}/teacher_archives/edu_work`,{params:params});
	},
	// 记录汇总
	collect(params){
		return axios.get(`${base.sq}/teacher_archives/collect`,{params:params});
	},
	// 考勤记录
	leaveList(params){
		return axios.get(`${base.sq}/leave/index`,{params:params});
	},
	studentleaveList(params){
		return axios.get(`${base.sq}/student_leave/statement`,{params:params});
	},
	// 学生评价
	studentTeacher(params){
		return axios.get(`${base.sq}/teacher_evaluation/student_teacher`,{params:params});
	},
	// 老师评价
	teacherStudent(params){
		return axios.get(`${base.sq}/teacher_evaluation/teacher_student`,{params:params});
	},
	// 导出
	teacherDerive(params){
		return axios.get(`${base.sq}/teacher_archives/derive`,{params:params});
	},
	// 导出
	teacherstatement(params){
		return axios.get(`${base.sq}/teacher_attendance/list`,{params:params});
	},
	// 请假导出
	teacherLeaveDerive(params){
		return axios.get(`${base.sq}/leave/derive`,{params:params});
	},
	// 请假详情
	teacherLeavedetails(params){
		return axios.post(`${base.sq}/teacher_attendance/details`,params);
	},
	// 教师考核
	teacherAssessList(params){
		return axios.get(`${base.sq}/teacher_assess/index`,{params:params});
	},
	// 添加教师考核
	addteacherAssess(params){
		return axios.post(`${base.sq}/teacher_assess/add`,params);
	},
	// 编辑教师考核
	editteacherAssess(params){
		return axios.post(`${base.sq}/teacher_assess/edit`,params);
	},
	// 删除教师考核
	delteacherAssess(params){
		return axios.post(`${base.sq}/teacher_assess/del`,params);
	},
}

export default teachers;
