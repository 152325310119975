import setting from "@/api/setting";
import {Message} from "element-ui";
import v2Semester from "@/api/v2/semester";

/**
 * 校区数据.
 */
const common = {
    namespaced: true,
    state: {
        campus: [],
        semesters: []
    },
    getters: {
        campus(state) {
            return state.campus
        },
        semesters(state) {
            return state.semesters
        }
    },
    mutations: {
        updateCampus(state, data) {
            state.campus = data
        },
        updateSemester(state, data) {
            state.semesters = data
        }
    },
    actions: {
        // 初始化数据
        async getData(ctx) {
            return Promise.all([
                setting.schoolCampusIndex().then(resp => {
                    if (resp.data.code === 1) {
                        ctx.commit('updateCampus', resp.data.data.rows)
                    } else {
                        Message.error(resp.data.msg)
                    }
                }),
                v2Semester.getList().then(resp => {
                    ctx.commit('updateSemester', resp.data)
                })
            ])
        }
    }
}

export default common
