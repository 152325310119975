/** 
 * api接口的统一出口
 */
// 登录
import login from '@/api/login';
// 管理
import setting from '@/api/setting';
// 教师档案
import teachers from '@/api/teachers';
// 排课
import paike from '@/api/paike';
// 学生档案
import student from '@/api/student';
// 岗位安排
import station from '@/api/station';
// 人员角色管理
import pepople from '@/api/pepople';
// 常规
import rule from '@/api/rule';
// 资产管理
import asset from '@/api/asset';
// 资产管理
import file from '@/api/file';
// 导出接口
export default {    
    login,
	setting,
    teachers,
	paike,
    student,
	station,
	pepople,
	rule,
	asset,
	file
    // ……
}