import axios from "@/utils/http";
import base from "@/api/base";
import {Message} from "element-ui";

const request = async (params) => {
    try {
        const resp = await axios({
            baseURL: base.host,
            ...params,
        })
        if (params.responseType === 'blob') {
            if (resp.headers['content-type'].indexOf('application/json') > -1) {
                const err = new Error(resp.data.msg)
                err.type = 'request'
                throw err
            }
            return resp.data;
        }
        if (resp.data.code === 1) {
            return resp.data
        }
        Message.error(resp.data.msg)
        const err = new Error(resp.data.msg)
        err.type = 'request'
        throw err
    } catch (e) {
        if (e.type !== 'request') {
            Message.error(e.data.msg)
            const err = new Error(e.data.msg)
            err.type = 'request'
            throw err
        } else {
            throw e
        }
    }
}

export default request