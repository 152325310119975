import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const setting = {
	// 学校信息    I
	schoolInfo() {
		return axios.get(`${base.sq}/setting/school_info`);
	},
	// 学科首页
	subjectIndex(params) {
		return axios.get(`${base.sq}/school_discipline/index`, {
			params:params
		});
	},
	arrangeIndex(params) {
		return axios.get(`${base.sq}/arrange/index`, {
			params:params
		});
	},
	// 课程分类列表
	subjectTypeList(params) {
		return axios.get(`${base.sq}/school_discipline_type/index`, {
			params:params
		});
	},
	// 添加课程分类
	addSubjectType(params) {
		return axios.post(`${base.sq}/school_discipline_type/add`, qs.stringify(params));
	},
	// 编辑课程分类
	editSubjectType(params) {
		return axios.post(`${base.sq}/school_discipline_type/edit`, qs.stringify(params));
	},
	// 删除课程分类
	delSubjectType(params) {
		return axios.post(`${base.sq}/school_discipline_type/del`, qs.stringify(params));
	},
	// 学区学科列表
	subjectList(params) {
		return axios.get(`${base.sq}/school_discipline/get_school_discipline`, {
			params:params
		});
	},
	// 编辑学校
	editSchool(params) {
		return axios.post(`${base.sq}/setting/school_info`, qs.stringify(params));
	},
	// 校区管理列表
	schoolCampusIndex() {
		return axios.get(`${base.sq}/school_campus/index`);
	},
	studentList(params) {
		return axios.get(`${base.sq}/grade_class/student_lists`,{
			params:params
		});
	},
	// 添加校区
	addCampus(params) {
		return axios.post(`${base.sq}/school_campus/add`, qs.stringify(params));
	},
	// 编辑校区
	editCampus(params) {
		return axios.post(`${base.sq}/school_campus/edit`, qs.stringify(params));
	},
	// 删除校区
	delCampus(params) {
		return axios.post(`${base.sq}/school_campus/del`, qs.stringify(params));
	},
	// 添加学科
	addSubject(params) {
		return axios.post(`${base.sq}/school_discipline/add`, qs.stringify(params));
	},
	// 编辑学科
	editSubject(params) {
		return axios.post(`${base.sq}/school_discipline/edit`, qs.stringify(params));
	},
	// 删除学科
	delSubject(params) {
		return axios.post(`${base.sq}/school_discipline/del`, qs.stringify(params));
	},
	// 添加学科到校区
	addDisciplineCampus(params) {
		return axios.post(`${base.sq}/school_discipline/add_discipline_campus`, qs.stringify(params));
	},
	//人员列表
	userList(params) {
		return axios.get(`${base.sq}/teacher/index`,{
			params:params
		});
	},
	//人员列表
	users(params) {
		return axios.get(`${base.sq}/user/list`,{
			params:params
		});
	},
	// 部门负责人
	Userlead(params) {
		return axios.post(`${base.sq}/school_department/lead`, qs.stringify(params));
	},
	// 科组负责人
	disciplinelead(params) {
		return axios.post(`${base.sq}/school_discipline/lead`, qs.stringify(params));
	},
	// 添加人员
	addUser(params) {
		return axios.post(`${base.sq}/teacher/add`, qs.stringify(params));
	},
	// 编辑人员
	editUser(params){
		return axios.post(`${base.sq}/teacher/edit`, qs.stringify(params));
	},
	// 删除人员
	delUser(params){
		return axios.post(`${base.sq}/teacher/del`, qs.stringify(params));
	},
	// 重置密码
	resetpwd(params){
		return axios.post(`${base.sq}/user/resetpwd`, qs.stringify(params));
	},
	// 禁用用户
	dimissiontime(params){
		return axios.post(`${base.sq}/user/dimissiontime`, qs.stringify(params));
	},
	// 用户解禁
	relieve(params){
		return axios.post(`${base.sq}/user/relieve`, qs.stringify(params));
	},
	// 导入
	teacherImport(params){
		return axios.post(`${base.sq}/teacher/import`, qs.stringify(params));
	},
	// 导出
	teacherDerive(params){
		return axios.get(`${base.sq}/teacher/derive`, {
			params:params
		});
	},
	// 部门列表
	sectionList(params) {
		return axios.post(`${base.sq}/school_department/index`,qs.stringify(params));
	},
	// 添加部门
	sectionAdd(params) {
		return axios.post(`${base.sq}/school_department/add`,qs.stringify(params));
	},
	// 修改部门
	sectionEdit(params) {
		return axios.post(`${base.sq}/school_department/edit`,qs.stringify(params));
	},
	// 删除部门
	sectionDel(params) {
		return axios.post(`${base.sq}/school_department/delete`,qs.stringify(params));
	},
	// 课程时段列表
	courseTimeList(params){
		return axios.get(`${base.sq}/curriculum_time/index`,{
			params:params
		});
	},
	// 添加时段
	addCourseTime(params){
		return axios.post(`${base.sq}/curriculum_time/add`, qs.stringify(params));
	},
	// 编辑时段
	editCourseTime(params){
		return axios.post(`${base.sq}/curriculum_time/edit`, qs.stringify(params));
	},
	// 删除时段
	delCourseTime(params){
		return axios.post(`${base.sq}/curriculum_time/del`, qs.stringify(params));
	},
	// 添加课程时段
	addCurriculumTime(params){
		return axios.post(`${base.sq}/curriculum_time/add_curriculum_time`, qs.stringify(params));
	},
	// 编辑课程时段
	editCurriculumTime(params){
		return axios.post(`${base.sq}/curriculum_time/edit_curriculum_time`, qs.stringify(params));
	},
	getCurriculumTime(params){
		return axios.get(`${base.sq}/curriculum_time/index_curriculum_time`, {
			params:params
		});
	},
	// 删除课程时段
	delCurriculumTime(params){
		return axios.post(`${base.sq}/curriculum_time/del_curriculum_time`, qs.stringify(params));
	},
	// 获取场地列表
	getPlaceList(params){
		return axios.get(`${base.sq}/school_place/index`, {
			params:params
		});
	},
	// 添加场地
	addPlace(params){
		return axios.post(`${base.sq}/school_place/add`, qs.stringify(params));
	},
	// 编辑场地
	editPlace(params){
		return axios.post(`${base.sq}/school_place/edit`, qs.stringify(params));
	},
	// 删除场地
	delPlace(params){
		return axios.post(`${base.sq}/school_place/del`, qs.stringify(params));
	},
	// 获取楼层列表
	getfloor(params){
		return axios.get(`${base.sq}/school_place_floor/get_floor`,{
			params:params
		});
	},
	// 编辑楼层
	editFloor(params){
		return axios.post(`${base.sq}/school_place_floor/edit`, qs.stringify(params));
	},
	// 添加楼层
	addFloor(params){
		return axios.post(`${base.sq}/school_place_floor/add`, qs.stringify(params));
	},
	// 删除楼层
	delFloor(params){
		return axios.post(`${base.sq}/school_place_floor/del`, qs.stringify(params));
	},
	// 获取房间列表
	getPlaceRoomList(params){
		return axios.get(`${base.sq}/school_place_room/index`, {params:params});
	},
	// 添加房间
	addRoom(params){
		return axios.post(`${base.sq}/school_place_room/add`, qs.stringify(params));
	},
	// 编辑房间
	editRoom(params){
		return axios.post(`${base.sq}/school_place_room/edit`, qs.stringify(params));
	},
	// 删除房间
	delRoom(params){
		return axios.post(`${base.sq}/school_place_room/del`, qs.stringify(params));
	},
	// 年级管理
	getGradeList(params){
		return axios.get(`${base.sq}/grade/index`, {
			params:params
		});
	},
	// 添加年级
	addGrade(params){
		return axios.post(`${base.sq}/grade/add`, params);
	},
	// 编辑年级
	editGrade(params){
		return axios.post(`${base.sq}/grade/edit`, params);
	},
	// 删除年级
	delGrade(params){
		return axios.post(`${base.sq}/grade/del`, qs.stringify(params));
	},
	// 班级层次管理
	getLevelList(params){
		return axios.get(`${base.sq}/grade_level/index`, {
			params:params
		});
	},
	// 添加班级层次
	addLevel(params){
		return axios.post(`${base.sq}/grade_level/add`, params);
	},
	// 编辑班级层次
	editLevel(params){
		return axios.post(`${base.sq}/grade_level/edit`, params);
	},
	// 删除班级层次
	delLevel(params){
		return axios.post(`${base.sq}/grade_level/del`, qs.stringify(params));
	},
	// 班级管理
	getClassList(params){
		return axios.get(`${base.sq}/grade_class/index`, {
			params:params
		});
	},
	// 添加班级
	addClass(params){
		return axios.post(`${base.sq}/grade_class/add`, params);
	},
	// 编辑班级
	editClass(params){
		return axios.post(`${base.sq}/grade_class/edit`, params);
	},
	// 删除班级
	delClass(params){
		return axios.post(`${base.sq}/grade_class/del`, qs.stringify(params));
	},
	// 班级毕业
	classGraduate(params) {
		return axios.post(`${base.sq}/grade_class/graduate`, qs.stringify(params))
	},
	// 班级学生名单列表
	classStudent(params){
		return axios.post(`${base.sq}/grade_class/student_list`, qs.stringify(params));
	},
	// 获取学年学期
	getSemesterList(params){
		return axios.get(`${base.sq}/semester/index`, {
			params:params
		});
	},
	// 获取学年学期信息
	getSemesterDetail(id){
		return axios.get(`${base.sq}/semester/get`, {
			params: { id }
		});
	},
	// 添加学年
	addSemester(params){
		return axios.post(`${base.sq}/semester/add`, qs.stringify(params));
	},
	// 编辑学年
	editSemester(params){
		return axios.post(`${base.sq}/semester/edit`, qs.stringify(params));
	},
	// 删除学年
	delSemester(params){
		return axios.post(`${base.sq}/semester/del`, qs.stringify(params));
	},
	// 获取学期列表
	getXueQiList(params){
		return axios.get(`${base.sq}/semester/semesters`, {
			params:params
		});
	},
	// 添加学期
	addXueQi(params){
		return axios.post(`${base.sq}/semester/add_semester`, qs.stringify(params));
	},
	// 添加学期
	addXueQi1(params){
		return axios.post(`${base.sq}/semester/add`, qs.stringify(params));
	},
	// 编辑学期
	editXueQi(params){
		return axios.post(`${base.sq}/semester/edit_semester`, qs.stringify(params));
	},
	// 删除学期
	delXueQi(params){
		return axios.post(`${base.sq}/semester/del`, qs.stringify(params));
	},
	// 设置默认值
	setDefault(params){
		return axios.post(`${base.sq}/semester/set_default`, qs.stringify(params));
	},
	// 评价配置列表
	evaluationConfigList(params){
		return axios.get(`${base.sq}/evaluation_config/index`, {
			params:params
		});
	},
	// 添加配置
	addConfog(params){
		return axios.post(`${base.sq}/evaluation_config/add`, qs.stringify(params));
	},
	// 删除配置
	delConfog(params){
		return axios.post(`${base.sq}/evaluation_config/del`, qs.stringify(params));
	},
	// 获取可用评价配置
	getCategory(params){
		return axios.get(`${base.sq}/evaluation_config/get_category`, {
			params:params
		});
	},
	// 年级学科课时列表
	gradeCourseHour(params){
		return axios.get(`${base.sq}/grade_course_hour/index`, {
			params:params
		});
	},
	// 添加年级学科课时
	addCourseHour(params){
		return axios.post(`${base.sq}/grade_course_hour/add`, qs.stringify(params));
	},
	// 文件上传
	uploadFile(params){
		return axios.post(`${base.sq}/Common/upload`, params);
	},
	// 首页快捷入口
	getUserRule(params){
		return axios.get(`${base.sq}/rule/get_user_rule`, {
			params:params
		});
	},
	// 获取学周
	getweekList(params){
		return axios.get(`${base.sq}/semester/week`, {
			params:params
		});
	},
	// 消息通知
	getSystemNotice(params){
		return axios.get(`${base.sq}/system_notice/index`, {
			params:params
		});
	},
	// 设置常用菜单
	setuserRule(params){
		return axios.post(`${base.sq}/rule/user_rule`,qs.stringify(params));
	},
	// 宿舍楼管理
	dormitoryList(params){
		return axios.post(`${base.sq}/Dormitory/dormitoryList`,qs.stringify(params));
	},
	// 添加宿舍楼
	addDormitory(params){
		return axios.post(`${base.sq}/Dormitory/add`,qs.stringify(params));
	},
	// 编辑宿舍楼
	editDormitory(params){
		return axios.post(`${base.sq}/Dormitory/edit`,qs.stringify(params));
	},
	// 删除宿舍楼
	delDormitory(params){
		return axios.post(`${base.sq}/Dormitory/del`,qs.stringify(params));
	},
	// 宿舍列表
	dormindex(params){
		return axios.get(`${base.sq}/Dorm/index`,{params:params});
	},
	// 添加宿舍
	addDorm(params){
		return axios.post(`${base.sq}/Dorm/add`,qs.stringify(params));
	},
	// 编辑宿舍
	editDorm(params){
		return axios.post(`${base.sq}/Dorm/edit`,qs.stringify(params));
	},
	// 删除宿舍楼
	delDorm(params){
		return axios.post(`${base.sq}/Dorm/del`,qs.stringify(params));
	},
	// 可分配的宿舍列表
	bindableList(params){
		return axios.get(`${base.sq}/SupervisorDorm/bindable`,{params:params});
	},
	// 宿管列表
	SupervisorDormList(params){
		return axios.get(`${base.sq}/SupervisorDorm/index`,{params:params});
	},
	// 添加宿管
	bindingDormitory(params){
		return axios.post(`${base.sq}/SupervisorDorm/bindingDormitory`,qs.stringify(params));
	},
	// 住宿生列表
	bedList(params){
		return axios.get(`${base.sq}/bed/bed_list`,{params:params});
	},
	// 获取床位
	bedIndex(params){
		return axios.get(`${base.sq}/bed/index`,{params:params});
	},
	// 宿舍安排
	bedbind(params){
		return axios.post(`${base.sq}/Bed/bind`,qs.stringify(params));
	},
	// 添加校历
	addSchoolCourse(params){
		return axios.post(`${base.sq}/school_course/add`,qs.stringify(params));
	},
	// 获取学期校历
	getSchoolCourseList(params){
		return axios.get(`${base.sq}/school_course/list`,{params:params});
	},
	// 获取当前校历事件
	getSchoolCourseDetail(params){
		return axios.get(`${base.sq}/school_course/detail`,{params:params});
	},
	// 通知消息已读
	readMsg(params){
		return axios.post(`${base.sq}/system_notice/read`,qs.stringify(params));
	},
	// 上传
	upload(params){
		return axios.post(`${base.sq}/Common/upload`,qs.stringify(params));
	},
	// 宿舍导入
	bedImport(params){
		return axios.post(`${base.sq}/bed/import`,params);
	},
	// 学生导入
	bindImport(params){
		return axios.post(`${base.sq}/bed/bind_import`,params);
	},
	// 奖惩列表
	rewardLists(params){
		return axios.get(`${base.sq}/RewardsRecord/rewards_pc`,{params:params});
	},
	protection(params){
		return axios.post(`${base.sq}/teacher/protection`,qs.stringify(params));
	},
	// 奖惩导出
	rewardsImport(params){
		return axios.post(`${base.sq}/RewardsRecord/rewards_import`,qs.stringify(params));
	},
	// 获取用户密保问题
	getProblem(params){
		return axios.post(`${base.sq}/teacher/getProblem`,qs.stringify(params));
	},
	updatePad(params){
		return axios.post(`${base.sq}/user/repassword`,qs.stringify(params));
	},
	getSms(params){
		return axios.post(`${base.sq}/teacher/getSms`,qs.stringify(params));
	},
	findPswd(params){
		return axios.post(`${base.sq}/teacher/findPswd`,qs.stringify(params));
	},
	// 获取奖惩分类列表
	rewardsTypeIndex(params){
		return axios.get(`${base.sq}/rewards_type/index`,{params:params});
	},
	// 添加奖惩分类
	addRewardsType(params){
		return axios.post(`${base.sq}/rewards_type/add`,qs.stringify(params));
	},
	// 删除奖惩分类
	delRewardsType(params){
		return axios.post(`${base.sq}/rewards_type/del`,qs.stringify(params));
	},
	// 编辑奖惩分类
	editRewardsType(params){
		return axios.post(`${base.sq}/rewards_type/edit`,qs.stringify(params));
	},
	// 添加奖惩配置
	addRewardsConfig(params){
		return axios.post(`${base.sq}/rewards_config/add`,qs.stringify(params));
	},
	// 删除奖惩配置
	delRewardsConfig(params){
		return axios.post(`${base.sq}/rewards_config/del`,qs.stringify(params));
	},
	// 编辑奖惩配置
	editRewardsConfig(params){
		return axios.post(`${base.sq}/rewards_config/edit`,qs.stringify(params));
	},
	// 奖惩配置
	RewardsConfigIndex(params){
		return axios.get(`${base.sq}/rewards_config/index`,{params:params});
	},
	// 添加小组评分
	submitsScore(params){
		return axios.post(`${base.sq}/team/submits`,qs.stringify(params));
	},
	// 小组列表
	teamList(params){
		return axios.post(`${base.sq}/team/lists`,qs.stringify(params));
	},
	// 删除小组列表
	teamdel(params){
		return axios.post(`${base.sq}/team/dels`,qs.stringify(params));
	},
	// 编辑小组列表
	teamedit(params){
		return axios.post(`${base.sq}/team/modify`,qs.stringify(params));
	},
	teamexports(params){
		return axios.post(`${base.sq}/team/exports`,qs.stringify(params));
	},
	// 年级得分统计
	gradeCensus(params){
		return axios.post(`${base.sq}/team/gradeCensus`,qs.stringify(params));
	},
	// 班级得分统计
	classCensus(params){
		return axios.post(`${base.sq}/team/classCensus`,qs.stringify(params));
	},
	// 学科得分统计
	subjectCensus(params){
		return axios.post(`${base.sq}/team/subjectCensus`,qs.stringify(params));
	},
	// 小组得分统计
	smallCensus(params){
		return axios.post(`${base.sq}/team/smallCensus`,qs.stringify(params));
	},
	// 约谈记录
	tutorChecks(params){
		return axios.post(`${base.sq}/tutor/checks`,qs.stringify(params));
	},
	// 导师得分列表
	tutorScoreList(params){
		return axios.post(`${base.sq}/tutor/tutorScoreList`,qs.stringify(params));
	},
	// 导师列表
	tutorList(params){
		return axios.post(`${base.sq}/tutor/getTutor`,qs.stringify(params));
	},
	// 添加导师得分
	scoreOpera(params){
		return axios.post(`${base.sq}/tutor/scoreOpera`,qs.stringify(params));
	},
	// 修改导师得分
	tutorModify(params){
		return axios.post(`${base.sq}/tutor/tutorModify`,qs.stringify(params));
	},
	// 删除导师得分
	tutorDels(params){
		return axios.post(`${base.sq}/tutor/tutorDels`,qs.stringify(params));
	},
	// 导师小组人员
	modifyList(params){
		return axios.post(`${base.sq}/tutor/modifyList`,qs.stringify(params));
	},
	// 添加小组人员
	modifyAdd(params){
		return axios.post(`${base.sq}/tutor/modifyAdd`,qs.stringify(params));
	},
	// 添加小组人员
	modifyedit(params){
		return axios.post(`${base.sq}/tutor/modify`,qs.stringify(params));
	},
	modifydels(params){
		return axios.post(`${base.sq}/tutor/dels`,qs.stringify(params));
	},
	modifyimport(params){
		return axios.post(`${base.sq}/tutor/import`,qs.stringify(params));
	},
	// 详情
	tutorDetails(params){
		return axios.post(`${base.sq}/tutor/details`,params);
	},
	checkOpera(params){
		return axios.post(`${base.sq}/tutor/checkOpera`,params);
	},
	// 导出谈心辅导
	exportsHeart(params){
		return axios.post(`${base.sq}/tutor/exportsHeart`,params);
	},
	// 导师阶段性自评列表
	stageList(params){
		return axios.post(`${base.sq}/tutor/stageList`,params);
	},
	// 导师阶段性自评导出
	exportsstage(params){
		return axios.post(`${base.sq}/tutor/exports`,params);
	},
	// 导入
	importScore(params){
		return axios.post(`${base.sq}/tutor/importScore`,params);
	},
	// 添加小组账号
	addOperator(params){
		return axios.post(`${base.sq}/team/addOperator`,params);
	},
	getlistsOperator(params){
		return axios.post(`${base.sq}/team/listsOperator`,params);
	},
	editOperator(params){
		return axios.post(`${base.sq}/team/modifyOperator`,params);
	},
	teamdelUser(params){
		return axios.post(`${base.sq}/team/delsOperator`,params);
	},
	RewardsRecordModify(params){
		return axios.post(`${base.sq}/RewardsRecord/modify`,params);
	},
	civilizationClassRecordModify(params){
		return axios.post(`${base.sq}/civilization_class_record/modify`,params);
	},
	tutorSetExport(params){
		return axios.post(`${base.sq}/export/exports1`,params);
	},
	tutorScoreExport(params){
		return axios.post(`${base.sq}/export/exports2`,params);
	},
	studentrewardsExport(params){
		return axios.post(`${base.sq}/export/exports3`,params);
	},
	classRewardsExport(params){
		return axios.post(`${base.sq}/export/exports4`,params);
	},
	dormRewardsExport(params){
		return axios.post(`${base.sq}/export/exports5`,params);
	},
	coursePatrolderive(params){
		return axios.get(`${base.sq}/course_patrol/derive`,{params:params});
	},
	deployExport(params){
		return axios.post(`${base.sq}/export/exports6`,params);
	},
	deployimport(params){
		return axios.post(`${base.sq}/export/import1`, qs.stringify(params));
	},
	// 资讯分类列表
	ArticleClassList(params){
		return axios.post(`${base.sq}/ArticleClass/lists`, qs.stringify(params));
	},
	// 添加咨询分类
	addArticleClass(params){
		return axios.post(`${base.sq}/ArticleClass/add`, qs.stringify(params));
	},
	// 编辑咨询分类
	editArticleClass(params){
		return axios.post(`${base.sq}/ArticleClass/modify`, qs.stringify(params));
	},
	// 删除咨询分类
	delArticleClass(params){
		return axios.post(`${base.sq}/ArticleClass/dels`, qs.stringify(params));
	},
	// 获取资讯列表
	getArticleList(params){
		return axios.post(`${base.sq}/Article/lists`, qs.stringify(params));
	},
	// 获取资讯分类列表
	getArticleClassList(params){
		return axios.post(`${base.sq}/Article/getClass`, qs.stringify(params));
	},
	// 添加资讯
	addArticle(params){
		return axios.post(`${base.sq}/Article/add`, qs.stringify(params));
	},
	// 删除资讯
	delArticle(params){
		return axios.post(`${base.sq}/Article/dels`, qs.stringify(params));
	},
	// 资讯详情
	ArticleDetails(params){
		return axios.post(`${base.sq}/Article/details`, qs.stringify(params));
	},
	// 添加轮播图
	addBanner(params){
		return axios.post(`${base.sq}/pic/add`, qs.stringify(params));
	},
	// 修改轮播图
	editBanner(params){
		return axios.post(`${base.sq}/pic/modify`, qs.stringify(params));
	},
	// 删除轮播图
	delBanner(params){
		return axios.post(`${base.sq}/pic/dels`, qs.stringify(params));
	},
	// 轮播图列表
	getBannerList(params){
		return axios.post(`${base.sq}/pic/lists`, qs.stringify(params));
	},
	// 个人单位导出
	exports8(params){
		return axios.post(`${base.sq}/export/exports8`, qs.stringify(params));
	},
	// 班级单位导出
	exports9(params){
		return axios.post(`${base.sq}/export/exports9`, qs.stringify(params));
	},
	// 宿舍单位导出
	exports12(params){
		return axios.post(`${base.sq}/export/exports12`, qs.stringify(params));
	},
	// 学生预警提示
	exports15(params){
		return axios.post(`${base.sq}/export/exports15`, qs.stringify(params));
	},
	// 班级预警提示
	exports16(params){
		return axios.post(`${base.sq}/export/exports16`, qs.stringify(params));
	},
	// 宿舍预警提示
	exports17(params){
		return axios.post(`${base.sq}/export/exports17`, qs.stringify(params));
	},
	// 编辑学生宿舍
	changeBed(params){
		return axios.post(`${base.sq}/Bed/change`, qs.stringify(params));
	},
	// 导出学生宿舍
	exports18(params){
		return axios.post(`${base.sq}/export/exports18`, qs.stringify(params));
	},
	// 导出嘉奖
	exports19(params){
		return axios.post(`${base.sq}/export/exports19`, qs.stringify(params));
	},
	// 导入嘉奖
	import19(params){
		return axios.post(`${base.sq}/export/import19`, params);
	},
	// 奖惩录入
	checkRegistration(params){
		return axios.post(`${base.sq}/RewardsRecord/checkRegistration`, params);
	},
	// 文明班评比导出
	checkRegistrationExport(params){
		return axios.post(`${base.sq}/CivilizationClassRecord/rewards_import`, params);
	},
	// 校园相册分类
	schoolPhotoType(params){
		return axios.post(`${base.sq}/school_file_type/index`, qs.stringify(params));
	},
	// 校园相册列表
	schoolPhotoList(params){
		return axios.post(`${base.sq}/SchoolPhoto/index`, qs.stringify(params));
	},
	// 添加相册分类
	addSchoolPhotoType(params){
		return axios.post(`${base.sq}/school_file_type/add`, qs.stringify(params));
	},
	// 编辑相册分类
	editSchoolPhotoType(params){
		return axios.post(`${base.sq}/school_file_type/edit`, qs.stringify(params));
	},
	// 删除相册分类
	delSchoolPhotoType(params){
		return axios.post(`${base.sq}/school_file_type/del`, qs.stringify(params));
	},
	// 添加相册
	addSchoolPhoto(params){
		return axios.post(`${base.sq}/SchoolPhoto/add`, qs.stringify(params));
	},
	// 编辑相册
	editSchoolPhoto(params){
		return axios.post(`${base.sq}/SchoolPhoto/edit`, qs.stringify(params));
	},
	// 删除相册
	delSchoolPhoto(params){
		return axios.post(`${base.sq}/SchoolPhoto/del`, qs.stringify(params));
	},
	// 校园视频
	getSchoolVideo(params){
		return axios.post(`${base.sq}/videos/school_video`, qs.stringify(params));
	},
	schoolVideoAdd(params){
		return axios.post(`${base.sq}/videos/school_video_add`, qs.stringify(params));
	},
	// 班级相册分类
	classPhotoType(params){
		return axios.post(`${base.sq}/class_file_type/index`, qs.stringify(params));
	},
	// 班级相册列表
	classPhotoList(params){
		return axios.post(`${base.sq}/ClassPhoto/index`, qs.stringify(params));
	},
	// 添加班级相册分类
	addClassPhotoType(params){
		return axios.post(`${base.sq}/class_file_type/add`, qs.stringify(params));
	},
	// 编辑班级相册分类
	editClassPhotoType(params){
		return axios.post(`${base.sq}/class_file_type/edit`, qs.stringify(params));
	},
	// 删除班级相册分类
	delClassPhotoType(params){
		return axios.post(`${base.sq}/class_file_type/del`, qs.stringify(params));
	},
	// 添加班级相册
	addClassPhoto(params){
		return axios.post(`${base.sq}/ClassPhoto/add`, qs.stringify(params));
	},
	// 编辑班级相册
	editClassPhoto(params){
		return axios.post(`${base.sq}/ClassPhoto/edit`, qs.stringify(params));
	},
	// 删除班级相册
	delClassPhoto(params){
		return axios.post(`${base.sq}/ClassPhoto/del`, qs.stringify(params));
	},
	// 班级视频
	getClassVideo(params){
		return axios.post(`${base.sq}/videos/class_video`, qs.stringify(params));
	},
	classVideoAdd(params){
		return axios.post(`${base.sq}/videos/class_video_add`, qs.stringify(params));
	},
	// 班级公告列表
	getClassNotice(params){
		return axios.post(`${base.sq}/class_notice/index`, qs.stringify(params));
	},
	// 班级公告详情
	classNoticeDetails(params){
		return axios.post(`${base.sq}/class_notice/detail`, qs.stringify(params));
	},
	// 班级公告发布
	addClassNotice(params){
		return axios.post(`${base.sq}/class_notice/add`, qs.stringify(params));
	},
	// 班级公告编辑
	editClassNotice(params){
		return axios.post(`${base.sq}/class_notice/edit`, qs.stringify(params));
	},
	// 班级公告删除
	delClassNotice(params){
		return axios.post(`${base.sq}/class_notice/del`, qs.stringify(params));
	},
	// 班级风采列表
	getClassMien(params){
		return axios.post(`${base.sq}/class_style/index`, qs.stringify(params));
	},
	// 班级公告详情
	classMienDetails(params){
		return axios.post(`${base.sq}/class_style/detail`, qs.stringify(params));
	},
	// 班级公告发布
	addClassMien(params){
		return axios.post(`${base.sq}/class_style/add`, qs.stringify(params));
	},
	// 班级公告编辑
	editClassMien(params){
		return axios.post(`${base.sq}/class_style/edit`, qs.stringify(params));
	},
	// 班级公告删除
	delClassMien(params){
		return axios.post(`${base.sq}/class_style/del`, qs.stringify(params));
	},
	// 系列表
	getXiList(params){
		return axios.post(`${base.sq}/xi/index`, qs.stringify(params));
	},
	// 添加系
	addXi(params){
		return axios.post(`${base.sq}/xi/add`, qs.stringify(params));
	},
	// 编辑系
	editXi(params){
		return axios.post(`${base.sq}/xi/edit`, qs.stringify(params));
	},
	// 删除系
	delXi(params){
		return axios.post(`${base.sq}/xi/del`, qs.stringify(params));
	},
	// 专业列表
	getZyList(params){
		return axios.post(`${base.sq}/zy/index`, qs.stringify(params));
	},
	// 添加专业
	addZy(params){
		return axios.post(`${base.sq}/zy/add`, qs.stringify(params));
	},
	// 编辑专业
	editZy(params){
		return axios.post(`${base.sq}/zy/edit`, qs.stringify(params));
	},
	// 删除专业
	delZy(params){
		return axios.post(`${base.sq}/zy/del`, qs.stringify(params));
	},
	// 实训基地列表
	trainingBaseList(params){
		return axios.post(`${base.sq}/TrainingBase/index`, qs.stringify(params));
	},
	// 添加实训基地
	addTrainingBase(params){
		return axios.post(`${base.sq}/TrainingBase/add`, qs.stringify(params));
	},
	// 编辑实训基地
	editTrainingBase(params){
		return axios.post(`${base.sq}/TrainingBase/edit`, qs.stringify(params));
	},
	// 删除实训基地
	delTrainingBase(params){
		return axios.post(`${base.sq}/TrainingBase/del`, qs.stringify(params));
	},
	// 实训设备列表
	trainingEquipmentList(params){
		return axios.post(`${base.sq}/TrainingEquipment/index`, qs.stringify(params));
	},
	// 编辑设备
	editTrainingEquipment(params){
		return axios.post(`${base.sq}/TrainingEquipment/edit`, qs.stringify(params));
	},
	// 添加设备
	addTrainingEquipment(params){
		return axios.post(`${base.sq}/TrainingEquipment/add`, qs.stringify(params));
	},
	// 删除设备
	delTrainingEquipment(params){
		return axios.post(`${base.sq}/TrainingEquipment/del`, qs.stringify(params));
	},
	// 导入基地
	importTrainingBase(params){
		return axios.post(`${base.sq}/TrainingBase/import`, params);
	},
	// 设备导入模板
	importTempTrainingEquipment(params){
		return axios.post(`${base.sq}/TrainingEquipment/importTemp`, qs.stringify(params));
	},
	// 设备导入
	importTrainingEquipment(params){
		return axios.post(`${base.sq}/TrainingEquipment/import`, params);
	},
	// 文明班评比-分类列表
	civilizationClassType(params){
		return axios.post(`${base.sq}/civilization_class_type/index`,  qs.stringify(params));
	},
	// 文明班评比-添加分类
	addCivilizationClassType(params){
		return axios.post(`${base.sq}/civilization_class_type/add`,  qs.stringify(params));
	},
	// 文明班评比-编辑分类
	editCivilizationClassType(params){
		return axios.post(`${base.sq}/civilization_class_type/edit`,  qs.stringify(params));
	},
	// 文明班评比-删除分类
	delCivilizationClassType(params){
		return axios.post(`${base.sq}/civilization_class_type/del`,  qs.stringify(params));
	},
	// 文明班评比-奖惩配置列表
	wcivilizationClassConfig(params){
		return axios.post(`${base.sq}/civilization_class_config/index`,  qs.stringify(params));
	},
	// 文明班评比-添加奖惩配置
	addWcivilizationClassConfig(params){
		return axios.post(`${base.sq}/civilization_class_config/add`,  qs.stringify(params));
	},
	// 文明班评比-编辑奖惩配置
	editWcivilizationClassConfig(params){
		return axios.post(`${base.sq}/civilization_class_config/edit`,  qs.stringify(params));
	},
	// 文明班评比-删除奖惩配置
	delWcivilizationClassConfig(params){
		return axios.post(`${base.sq}/civilization_class_config/del`,  qs.stringify(params));
	},
	// 评比列表
	civilizationClassRecordList(params){
		return axios.post(`${base.sq}/civilization_class_record/rewards_pc`,  qs.stringify(params));
	},
	// 教研组列表
	getStaffList(params){
		return axios.post(`${base.sq}/jy/index`,qs.stringify(params));
	},
	// 添加教研组
	addStaff(params){
		return axios.post(`${base.sq}/jy/add`, qs.stringify(params));
	},
	// 编辑教研组
	editStaff(params){
		return axios.post(`${base.sq}/jy/edit`, qs.stringify(params));
	},
	// 删除教研组
	delStaff(params){
		return axios.post(`${base.sq}/jy/del`, qs.stringify(params));
	},
	// 教室列表
	getClassRoom(params){
		return axios.post(`${base.sq}/js/index`, qs.stringify(params));
	},
	// 添加教室
	addClassRoom(params){
		return axios.post(`${base.sq}/js/add`, qs.stringify(params));
	},
	// 编辑教室
	editClassRoom(params){
		return axios.post(`${base.sq}/js/edit`, qs.stringify(params));
	},
	// 删除教室
	delClassRoom(params){
		return axios.post(`${base.sq}/js/del`, qs.stringify(params));
	},
	// 删除教室
	expenditureList(params){
		return axios.post(`${base.sq}/expenditure/index`, qs.stringify(params));
	},
	// 审核
	auditExpenditure(params){
		return axios.post(`${base.sq}/expenditure/audit`, qs.stringify(params));
	},
	// 导出
	exportsExpenditure(params){
		return axios.post(`${base.sq}/expenditure/exports`, qs.stringify(params));
	},
	// 出校列表
	allLeaveSchoolList(params){
		return axios.post(`${base.sq}/leave_school/all_list`, qs.stringify(params));
	},
	// 我的审核列表
	leaveSchoolList(params){
		return axios.post(`${base.sq}/leave_school/index`, qs.stringify(params));
	},
	// 招生列表
	AdmissionsWriteList(params){
		return axios.post(`${base.sq}/AdmissionsWrite/index`, qs.stringify(params));
	},
	// 导出招生列表
	AdmissionsWriteExports(params){
		return axios.post(`${base.sq}/AdmissionsWrite/exports`, qs.stringify(params));
	},
	// 招生列表-编辑
	editAdmissionsWrite(params){
		return axios.post(`${base.sq}/AdmissionsWrite/edit`, qs.stringify(params));
	},
	// 招生列表-删除
	delAdmissionsWrite(params){
		return axios.post(`${base.sq}/AdmissionsWrite/del`, qs.stringify(params));
	},
	// 食材管理-入库列表
	foodMaterialIndex(params){
		return axios.get(`${base.sq}/food_material/index`,{params:params});
	},
	// 食材管理-添加入库
	addFoodMaterial(params){
		return axios.post(`${base.sq}/food_material/add`, qs.stringify(params));
	},
	// 食材管理-编辑入库
	editFoodMaterial(params){
		return axios.post(`${base.sq}/food_material/edit`, qs.stringify(params));
	},
	// 食材管理-出库列表
	foodMaterialOutIndex(params){
		return axios.get(`${base.sq}/food_material_out/index`,{params:params});
	},
	// 食材管理-添加出库
	addFoodMaterialOut(params){
		return axios.post(`${base.sq}/food_material_out/add`, qs.stringify(params));
	},
	// 授课安排
	teachingIndex(params){
		return axios.get(`${base.sq}/teaching/index`, {params:params});
	},
	// 授课安排-添加
	addTeaching(params){
		return axios.post(`${base.sq}/teaching/add`, qs.stringify(params));
	},
	// 授课安排-编辑
	editTeaching(params){
		return axios.post(`${base.sq}/teaching/edit`, qs.stringify(params));
	},
	// 授课安排-导入模板
	importTemplate(params){
		return axios.post(`${base.sq}/teaching/importTemplate`, qs.stringify(params));
	},
	// 授课安排-导入
	importTeaching(params){
		return axios.post(`${base.sq}/teaching/import`, qs.stringify(params));
	},
	// 成绩管理
	achievementIndex(params){
		return axios.get(`${base.sq}/exam_results/index`, { params });
	},
	// 教材列表
	getTextBookList(params){
		return axios.get(`${base.sq}/teaching_material/index`, {params:params});
	},
	// 添加教材
	addTextBook(params){
		return axios.post(`${base.sq}/teaching_material/add`, qs.stringify(params));
	},
	// 编辑教材
	editTextBook(params){
		return axios.post(`${base.sq}/teaching_material/edit`, qs.stringify(params));
	},
	// 导出教材
	derivepaseTextBook(params){
		return axios.get(`${base.sq}/teaching_material/derivepase`, {params:params});
	},
	// 教学计划-列表
	teachingPlanList(params){
		return axios.post(`${base.sq}/teaching_plan/index`,qs.stringify(params));
	},
	// 教学计划-添加
	addTeachingPlan(params){
		return axios.post(`${base.sq}/teaching_plan/add`,qs.stringify(params));
	},
	// 教学计划-编辑
	editTeachingPlan(params){
		return axios.post(`${base.sq}/teaching_plan/edit`,qs.stringify(params));
	},
	// 教学计划-删除
	delTeachingPlan(params){
		return axios.post(`${base.sq}/teaching_plan/del`,qs.stringify(params));
	},
	// 教学计划-导出
	exportsTeachingPlan(params){
		return axios.post(`${base.sq}/teaching_plan/exports`,qs.stringify(params));
	},
	// 班牌账号列表
	brandUserList(params){
		return axios.post(`${base.sq}/brand_user/index`,qs.stringify(params));
	},
	// 班牌账号-添加
	// addBrandUser(params){
	// 	return axios.post(`${base.sq}/brand_user/add`,qs.stringify(params));
	// },
	// // 班牌账号-编辑
	// editBrandUser(params){
	// 	return axios.post(`${base.sq}/brand_user/edit`,qs.stringify(params));
	// },
	// 班牌账号-删除
	delBrandUser(params){
		return axios.post(`${base.sq}/brand_user/del`,qs.stringify(params));
	},
	// 班牌账号-班级
	BrandUserlassList(params){
		return axios.post(`${base.sq}/brand_user/classList`,qs.stringify(params));
	},
	// 实训基地检查-列表
	trainingBaseCheckList(params){
		return axios.post(`${base.sq}/training_base_check/index`,qs.stringify(params));
	},
	// 实训基地检查-添加
	addtrainingBaseCheck(params){
		return axios.post(`${base.sq}/training_base_check/add`,qs.stringify(params));
	},
	// 实训基地检查-列表
	edittrainingBaseCheck(params){
		return axios.post(`${base.sq}/training_base_check/edit`,qs.stringify(params));
	},
	// 实训基地检查-列表
	deltrainingBaseCheck(params){
		return axios.post(`${base.sq}/training_base_check/del`,qs.stringify(params));
	},
	// 实训基地检查-导出
	exportstrainingBaseCheck(params){
		return axios.post(`${base.sq}/training_base_check/exports`,qs.stringify(params));
	},
	// 就业管理-列表
	getPracticeList(params){
		return axios.post(`${base.sq}/employment_administration/index`,qs.stringify(params));
	},
	// 就业管理-编辑
	editPractice(params){
		return axios.post(`${base.sq}/employment_administration/edit`,qs.stringify(params));
	},
	// 就业管理-删除
	delPractice(params){
		return axios.post(`${base.sq}/employment_administration/del`,qs.stringify(params));
	},
	// 就业管理-导出
	exportsPractice(params){
		return axios.post(`${base.sq}/employment_administration/exports`,qs.stringify(params));
	},
	// 电子班牌添加账号
	addBrandUser(params){
		return axios.post(`${base.sq}/brand_user/add`,qs.stringify(params));
	},
	// 电子班牌编辑账号
	editBrandUser(params){
		return axios.post(`${base.sq}/brand_user/edit`,qs.stringify(params));
	},
	// 电子班牌可添加班级、
	getBrandUserClassList(params){
		return axios.post(`${base.sq}/brand_user/classList`,qs.stringify(params));
	},
	// 添加教材订单、
	addOrder(params){
		return axios.post(`${base.sq}/teaching_material_order/add`,qs.stringify(params));
	},
	// 教材订单列表
	getOrderlist(params){
		return axios.get(`${base.sq}/teaching_material_order/index`,{params:params});
	},
	// 编辑教材订单
	editOrder(params){
		return axios.post(`${base.sq}/teaching_material_order/edit`,qs.stringify(params));
	},
	// 导出订单
	deriveOrder(params){
		return axios.get(`${base.sq}/teaching_material_order/derive`,{params:params});
	},
	// 订单详情
	OrderDetail(params){
		return axios.get(`${base.sq}/teaching_material_order/get`,{params:params});
	},
	// 竞赛管理-列表
	getCompetitionList(params){
		return axios.get(`${base.sq}/Competition/index`,{params:params});
	},
	// 竞赛管理-添加
	addCompetition(params){
		return axios.post(`${base.sq}/Competition/add`,qs.stringify(params));
	},
	// 荣誉列表
	getHonorList(params){
		return axios.post(`${base.sq}/honor/index`,qs.stringify(params));
	},
	// 荣誉列表-添加
	addHonor(params){
		return axios.post(`${base.sq}/honor/add`,qs.stringify(params));
	},
	// 荣誉列表-编辑
	editHonor(params){
		return axios.post(`${base.sq}/honor/edit`,qs.stringify(params));
	},
	// 荣誉列表-删除
	delHonor(params){
		return axios.post(`${base.sq}/honor/del`,qs.stringify(params));
	},
	// 资产导入
	importHouse(params){
		return axios.post(`${base.sq}/property_warehouse/import`, params);
	},
	// 进出校导出
	exportsLeaveSchool(params){
		return axios.post(`${base.sq}/leave_school/exports`,qs.stringify(params));
	},
	// 操行评定导出
	exportsConduct(params){
		return axios.post(`${base.sq}/evaluation_conduct/exports`,qs.stringify(params));
	},
	// 门禁-设备列表
	getDeviceList(params){
		return axios.get(`${base.sy}/door/device/index`,{params:params});
	},
	// 添加设备
	addDevice(params){
		return axios.post(`${base.sy}/door/device/add`,qs.stringify(params));
	},
	// 编辑设备
	editDevice(params){
		return axios.post(`${base.sy}/door/device/edit`,qs.stringify(params));
	},
	// 删除设备
	delDevice(params){
		return axios.post(`${base.sy}/door/device/del`,qs.stringify(params));
	},
	// 门禁-通行时间段列表
	getRulesList(params){
		return axios.get(`${base.sy}/door/rules/index`,{params:params});
	},
	// 添加通行时间段列表
	addRules(params){
		return axios.post(`${base.sy}/door/rules/add`,qs.stringify(params));
	},
	// 编辑通行时间段列表
	editRules(params){
		return axios.post(`${base.sy}/door/rules/edit`,qs.stringify(params));
	},
	// 删除通行时间段列表
	delRules(params){
		return axios.post(`${base.sy}/door/rules/del`,qs.stringify(params));
	},
	// 门禁-出入记录
	getRecordIndex(params){
		return axios.get(`${base.sy}/door/record/index`,{params:params});
	},
	// 门禁-班级出入记录
	getClassRecordIndex(params){
		return axios.get(`${base.sy}/door/record/my_class`,{params:params});
	},
	// 实训基地导出
	TrainingBaseExports(params){
		return axios.post(`${base.sq}/TrainingBase/exports`,qs.stringify(params));
	},
	// 招生导入
	AdmissionsWriteImports(params){
		return axios.post(`${base.sq}/AdmissionsWrite/imports`,params);
	},
	// 就业管理导入
	employmentAdministrationImports(params){
		return axios.post(`${base.sq}/employment_administration/imports`,params);
	},
	// 课程导入
	arrangeImports(params){
		return axios.post(`${base.sq}/arrange/imports`,params);
	},

}

export default setting;
