/**
 * 接口域名的管理
 */
// const apiHost = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_HOST : process.env.VUE_APP_PROD;
const apiHost = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_PROD : process.env.VUE_APP_PROD;
const base = {
	host: apiHost,
	sq: apiHost + '/v1/school_api',
	sy: apiHost + '/v2/school_api',
	bd: apiHost + '/school_api/',
	imgUrl: apiHost
	// sq: 'http://hzyzdj.zhiyunapi.com/v1/school_api',
	// bd: 'http://hzyzdj.zhiyunapi.com/school_api/',
	// imgUrl: 'http://hzyzdj.zhiyunapi.com'
}

export default base;
