import dayjs from "dayjs";
import { Loading } from "element-ui";

export default {
    install(Vue) {
        Vue.filter('date', (time, format) => {
            return dayjs(time * 1000).format(format)
        })

        /**
         * 下载blob接口文件.
         * @param func 接口请求函数
         * @param title 文件名
         * @returns {Promise<void>}
         */
        Vue.prototype.$downloadFile = async (func, title = '导入模板.xlsx') => {
            const load = Loading.service({ text: '加载中...' })
            const respData = await func()
            const reader = new FileReader()
            reader.readAsDataURL(respData)
            reader.onload = (res) => {
                const a = document.createElement('a')
                a.setAttribute('href', res.target.result)
                a.setAttribute('download', title)
                a.click()
                load.close()
            }
        }
    }
}
