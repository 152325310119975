import Vue from 'vue'
import Vuex from 'vuex'
import common from "@/store/modules/common";

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		common,
	},
	state: {
		token: '',
		nickname: '',
		avatar: '',
		meunIndex: '',
		passwdUpdate: window.localStorage.getItem('passwdUpdate') === 'false' ? false : true, // true为不用修改 false为强制修改密码
		meunList: [],
	},
	mutations: {
		settoken(state, payload) {
			state.token = payload;
			window.localStorage.setItem("token", state.token);
		},
		nickname(state, payload) {
			state.nickname = payload;
			window.localStorage.setItem("nickname", state.nickname);
		},
		avatar(state, payload) {
			state.avatar = payload;
			window.localStorage.setItem("avatar", state.avatar);
		},
		meunIndex(state, payload) {
			state.meunIndex = payload;
		},
		meunList(state, arr) {
			console.log(arr)
			state.meunList = arr;
		},
		setPasswdUpdate(state, passwdUpdate) {
			state.passwdUpdate = passwdUpdate;
			window.localStorage.setItem("passwdUpdate", passwdUpdate);
		}
	},
	actions: {

	},
	getters: {

	}
})

export default store