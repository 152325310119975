// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import dayjs from 'dayjs'

import vcharts from '@/components/v-echart/src/index.es'
Vue.use(vcharts )
import 'v-charts/lib/style.css';
import * as Common from './api/common.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/css/common.css"
import "../public/icon/iconfont.css"
import animate from 'animate.css'
Vue.use(animate)


import store from './store' // 导入vuex文件
import api from './api' // 导入api接口
import common from './utils/common.js' // 导入commom函数
import utils from './utils/utils'
import './filters'

Vue.use(utils)
Vue.prototype.$api = api; // 将api挂载到vue的原型上复制代码
Vue.prototype.$common = common; // 将api挂载到vue的原型上复制代码
Vue.prototype.$store = store;
Vue.prototype.$common = Common;

// 拖拽
import VueDND from 'awe-dnd';
Vue.use(VueDND)

import Print from '@/plugs/print'
Vue.use(Print) // 注册

import FullCalendar from 'vue-fullcalendar'
Vue.use(FullCalendar)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.config.productionTip = false

Vue.use(ElementUI);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: (h) => <App />,
})
