<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import UserService from "@/api/v2/user";
import router from "@/router";

export default {
  name: 'App',
  data() {
    return {
      ok: false
    }
  },
  mounted() {
    UserService.check()
      .then(() => {
        this.ok = true
        this.$store.dispatch('common/getData')
      })
      .catch(() => {
        this.ok = false
        if (process.env.NODE_ENV === 'development') {
          if (this.$route.path !== '/') {
            router.replace({
              path: '/',
            });
          } else {
            this.ok = true
          }
          // location.href = 'http://localhost:8000/umi/login/main?redirect_url=' + location.href
        } else {
          // location.href = '/umi/login/main?redirect_url=' + location.href
        }
      })
  }
};
</script>

<style lang="scss">
.el-tooltip__popper {
  max-width: 1200px;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.729vw;
}

div {
  font-size: 0.729vw;
}

body {
  margin: 0;
}

.icon-nan {
  color: #5dc0ff;
  font-size: 0.729vw;
}

.icon-nv {
  color: #eb5900;
  font-size: 0.729vw;
}

.mouse {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.el-breadcrumb {
  margin-bottom: 20px;
}
</style>
