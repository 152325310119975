import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const paike = {
	// 上传排课数据
	uploadPakei(params){
		return axios.post(`${base.sq}/curriculum_arrangement/curriculum_arrangement`, params);
	},
	// 行政班排课列表
	getCourseindex(params) {
		return axios.get(`${base.sq}/course/index`, {
			params: params
		});
	},
	// 排课规则列表
	getCourseRuleIndex(params) {
		return axios.get(`${base.sq}/course_admini_rule/index`, {
			params: params
		});
	},
	// 新增排课规则
	addCourseRule(params){
		return axios.post(`${base.sq}/course_admini_rule/add`, qs.stringify(params));
	},
	// 编辑排课规则
	editCourseRule(params){
		return axios.post(`${base.sq}/course_admini_rule/edit`, qs.stringify(params));
	},
	// 新增排课
	addCourse(params){
		return axios.post(`${base.sq}/course/add`, qs.stringify(params));
	},
	// 编辑排课
	editCourse(params){
		return axios.post(`${base.sq}/course/edit`, qs.stringify(params));
	},
	// 修改排课规则状态
	statusCourse(params){
		return axios.post(`${base.sq}/course/set`, qs.stringify(params));
	},
	// 排课规则配置
	getCourseRuleSetting(params) {
		return axios.get(`${base.sq}/course_admini_rule/setting`, {
			params: params
		});
	},
	// 排课规则配置保存
	saveCourseRuleSetting(id, params) {
		return axios.post(`${base.sq}/course_admini_rule/setting?id=${id}`, params);
	},
	// 获取班级
	getCourseClasss(params) {
		return axios.get(`${base.sq}/course/classs`, {
			params: params
		});
	},
	// 获取班级教师
	getCourseTecher(params) {
		return axios.get(`${base.sq}/job_config/classs_subject`, {
			params: params
		});
	},
	// 获取学科列表
	getSubjectList(params){
		return axios.get(`${base.sq}/job_config/subject`, {
			params: params
		});
	},
	// 获取学科老师
	getSubjectTecherList(params){
		return axios.get(`${base.sq}/job_config/subject_teacher`, {
			params: params
		});
	},
	// 排课设置保存
	setCourse(id, params){
		return axios.post(`${base.sq}/course_config/edit?id=${id}`, params);
	},
	// 获取排课信息新
	getCourseConfig(params){
		return axios.get(`${base.sq}/course_config/get`, {
			params: params
		});
	},
	// 获取排课列表
	getCourseList(params){
		return axios.post(`${base.sq}/curriculum_arrangement/curriculum_arrangement_temporary`, qs.stringify(params));
	},
	// 一键排课
	yjpk(params){
		return axios.post(`${base.sq}/curriculum_arrangement/curriculum_arrangement`, qs.stringify(params));
	},
	// 获取临时课表
	lsCourse(params){
		return axios.post(`${base.sq}/curriculum_arrangement/course`, qs.stringify(params));
	},
	// 获取学科教师
	getDisciplineList(params){
		return axios.post(`${base.sq}/curriculum_arrangement/discipline_list`, qs.stringify(params));
	},
	// 编辑保存课表
	updateCourse(params){
		return axios.post(`${base.sq}/curriculum_arrangement/update`, qs.stringify(params));
	},
	// 验证是否有排课数据
	checkCourse(params){
		return axios.post(`${base.sq}/curriculum_arrangement/check`, qs.stringify(params));
	},
	// 同步排课数据
	syncCourse(params){
		return axios.post(`${base.sq}/curriculum_arrangement/sync`, qs.stringify(params));
	},
	// 课表查询
	getcourseLists(params){
		return axios.get(`${base.sy}/arrange/table/index`, {
			params,
		});
	},
	
}

export default paike;
