import request from "@/utils/request";

export default class UserService {
    static async check() {
        await request({
            url: '/v1/school_api/user/check',
            skipError: true
        })
    }
    /**
     * 获取拥有的权限
     */
    static async getRules() {
        const resp = await request({
            url: '/v1/school_api/user/flat_rules',
        })
        return resp.data
    }

}
