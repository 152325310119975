import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const station = {
	// 岗位列表 
	getJobList(params){
		return axios.get(`${base.sq}/job/index`, {
			params:params
		});
	},
	// 获取年级默认学科
	getGradeDiscipline(params){
		return axios.get(`${base.sq}/grade/get_grade_discipline`, {
			params:params
		});
	},
	// 添加岗位
	addJob(params){
		return axios.post(`${base.sq}/job/add`, qs.stringify(params));
	},
	// 编辑岗位
	editJob(params){
		return axios.post(`${base.sq}/job/edit`, qs.stringify(params));
	},
	// 删除岗位
	delJob(params){
		return axios.post(`${base.sq}/job/del`, qs.stringify(params));
	},
	// 获取岗位配置数据
	getJobConfig(params){
		return axios.get(`${base.sq}/job/setting`, {
			params:params
		});
	},
	// 获取学科列表
	getXuekeList(params){
		return axios.get(`${base.sq}/job_discipline/index`, {
			params:params
		});
	},
	// 岗位老师列表
	getLaoshiList(params){
		return axios.get(`${base.sq}/job_discipline_data/index`, {
			params:params
		});
	},
	// 添加岗位学科
	addDiscipline(id,params){
		return axios.post(`${base.sq}/job_discipline/add?id=${id}`, {
			data:params
		});
	},
	// 保存岗位班级
	saveDiscipline(id,params){
		return axios.post(`${base.sq}/job/setting?id=${id}`, params);
	},
	// 课时统计
	statistics(params){
		return axios.get(`${base.sq}/course_config/statistics`, {
			params:params
		});
	},
	// 社团是指导计划列表
	getClubWorkPlanList(params){
		return axios.post(`${base.sq}/ClubWorkPlan/index`, qs.stringify(params));
	},
	// 添加社团指导计划
	addClubWorkPlan(params){
		return axios.post(`${base.sq}/ClubWorkPlan/add`, qs.stringify(params));
	},
	// 删除社团指导计划
	delClubWorkPlan(params){
		return axios.post(`${base.sq}/ClubWorkPlan/del`, qs.stringify(params));
	},
	// 社团指导计划详情
	getWorkPlanDetailList(params){
		return axios.post(`${base.sq}/WorkPlanDetail/index`, qs.stringify(params));
	},
	// 添加社团指导计划详情
	addWorkPlanDetail(params){
		return axios.post(`${base.sq}/WorkPlanDetail/add`, qs.stringify(params));
	},
	// 编辑社团指导计划详情
	editWorkPlanDetail(params){
		return axios.post(`${base.sq}/WorkPlanDetail/edit`, qs.stringify(params));
	},
	// 删除社团指导计划详情
	delWorkPlanDetail(params){
		return axios.post(`${base.sq}/WorkPlanDetail/del`, qs.stringify(params));
	},
	// 授课申请列表
	getSchoolingList(params){
		return axios.post(`${base.sq}/ClubSchoolteaching/index`, qs.stringify(params));
	},
	// 添加授课申请
	addSchooling(params){
		return axios.post(`${base.sq}/ClubSchoolteaching/add`, qs.stringify(params));
	},
	// 编辑授课申请
	editSchooling(params){
		return axios.post(`${base.sq}/ClubSchoolteaching/edit`, qs.stringify(params));
	},
	// 删除授课申请
	delSchooling(params){
		return axios.post(`${base.sq}/ClubSchoolteaching/del`, qs.stringify(params));
	},
	// 操行评定
	evaluationConductList(params){
		return axios.post(`${base.sq}/evaluation_conduct/all_list`, qs.stringify(params));
	},
	// 删除评定
	delEvaluationConduct(params){
		return axios.post(`${base.sq}/evaluation_conduct/del`, qs.stringify(params));
	},
	
}

export default station;